import React, { useEffect, useState, Component } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Charts from "../components/stats/charts"
import Statsmap from "../components/stats/statsmap"
import SEO from "../components/seo"
import "../scss/react-table.scss"
import "../scss/stats.scss"

export default function Stats({ data }) {
  const [latestTransaction, setLatestTransaction] = useState("")

  const callback = (data) => {
    data.some((val, i) => {
      if (val.name !== "TravelbyBit Online Booking" && val.name !== "") {
        setLatestTransaction(val)
        return true
      }
    })
  }

  return (
    <>
      <SEO title="Stats" />
      <Page>
        <section id="content" className="gray-area">
          <div className="container">
            <Charts callback={callback} />

            <div className="title reactable">Latest Merchant Activity</div>
            <div className="map">
              {latestTransaction ? (
                <Statsmap
                  latest={latestTransaction}
                  merchants={data.allGoogleSheet1Sheet.edges}
                />
              ) : null}
            </div>
          </div>
        </section>
      </Page>
    </>
  )
}

export const query = graphql`
  query StatsMerchantDetailQuery {
    allGoogleSheet1Sheet {
      edges {
        node {
          merchant
          region
          category
          latitude
          longitude
        }
      }
    }
  }
`

const Page = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`
