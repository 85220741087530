import React from "react"

export default function Marker(props) {
  return (
    <React.Fragment>
      {props.$hover ? <div className="speech-bubble">{props.text}</div> : null}

      <div
        className={"marker " + (props.latest ? "pulsating-circle" : "")}
      ></div>
    </React.Fragment>
  )
}
