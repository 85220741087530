import React, { Component } from "react"
import axios from "axios"
import GoogleMap from "google-map-react"
import Marker from "./marker"

export default class Statsmap extends Component {
  state = {
    merchants: {},
    callback_latestMerchant: "",
    latestMerchant: "",
    findmatch: false,
  }

  componentDidMount = async () => {
    this.setState({ callback_latestMerchant: this.props.latest })
    try {
      const merchants = await axios.get(
        `https://cors-anywhere.herokuapp.com/${process.env.MERCHANT_DATA_API}/api/getMerchants`
      )
      this.merchant(merchants.data)
    } catch (e) {
      throw e
    }
  }

  cleanPath = (path) => {}

  merchant(mMap) {
    let getMerchants = []
    let dataName = this.state.callback_latestMerchant.name.toLowerCase()
    mMap.forEach((mMap) => {
      let row = {}
      if (!!mMap.lat && !!mMap.lng) {
        row.latest = false
        row.merchant = mMap.merchant
        row.lng = mMap.lng
        row.lat = mMap.lat

        if (!this.state.findmatch) {
          let merchantName = mMap.merchant.toLowerCase()
          if (
            merchantName.search(dataName) != -1 ||
            merchantName === dataName
          ) {
            row.latest = true
            let latestMerchant = mMap
            switch (this.state.callback_latestMerchant.actualAsset) {
              case "BNB":
                latestMerchant.coin = "Binance-Coin"
                break
              case "BTC(Lightning)":
                latestMerchant.coin = "Btc-Lightning"
                break
                break
              case "Others":
                latestMerchant.coin = this.state.callback_latestMerchant.details
                break
              default:
                latestMerchant.coin = this.state.callback_latestMerchant.actualAsset
            }
            this.setState({ latestMerchant: latestMerchant })
          }
        }

        getMerchants.push(row)
      }
    })
    this.setState({ merchants: getMerchants })
  }

  render() {
    const { merchants } = this.state
    return (
      <React.Fragment>
        {this.state.latestMerchant ? (
          <div className="latest">
            <div className="title">Latest Transaction</div>
            <div className="inline">
              <span className="name">{this.state.latestMerchant.merchant}</span>
              <br /> {this.state.latestMerchant.category}
              <br /> {this.state.latestMerchant.region}
              <br />
            </div>
            <div className="inline">
              <img
                src={require(`../../images/icon/Logo-${this.state.latestMerchant.coin.replace(
                  / /g,
                  "-"
                )}.png`)}
              />
            </div>
          </div>
        ) : (
          <div className="latest"></div>
        )}
        {this.state.callback_latestMerchant ? (
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyDew0PvMMFi1HWdQypW3WnUOXtTl2SM3_M",
            }}
            defaultCenter={{
              lat: -28.463863,
              lng: 134.046194,
            }}
            options={{
              zoomControl: false,
              mapTypeControl: false,
              gestureHandling: "none",
              fullscreenControl: false,
              restriction: {
                latLngBounds: {
                  north: -10.77,
                  south: -43.56,
                  west: 113.93,
                  east: 153.52,
                },
                strictBounds: false,
              },
            }}
            defaultZoom={2}
            onChildMouseEnter={this._onChildMouseEnter}
            onChildMouseLeave={this._onChildMouseLeave}
          >
            {Object.entries(merchants).map(([key, val]) => {
              return (
                <Marker
                  lat={val.lng}
                  lng={val.lat}
                  text={val.merchant}
                  key={key}
                  latest={val.latest}
                />
              )
            })}
          </GoogleMap>
        ) : null}
      </React.Fragment>
    )
  }
}
