import React, { Component } from "react"
import { differenceInDays, differenceInMinutes } from "date-fns"
import Table from "./Table"
import { PieChart } from "react-minimal-pie-chart"
import styled from "styled-components"
import lightning from "../../images/icon/Logo-Btc-Lightning.png"
import { FaAngleDown } from "react-icons/fa"

var transactions = []
const columns = [
  {
    Header: "Age",
    accessor: "age",
    sortMethod: (a, b) => {
      let c = sortAge(a)
      let d = sortAge(b)
      if (c === d) {
        a = parseInt(a, 10)
        b = parseInt(b, 10)
        if (a > b) {
          return 1
        }
        if (a < b) {
          return -1
        }
      } else {
        if (c > d) {
          return 1
        }
        if (c < d) {
          return -1
        }
      }
    },
  },
  {
    Header: "Type",
    accessor: "name",
  },
  {
    Header: "Paid with",
    accessor: "newAsset",
  },
  {
    Header: "Amount",
    accessor: "amount",
    sortMethod: (a, b) => {
      a = parseFloat(a.substring(1))
      b = parseFloat(b.substring(1))
      if (a > b) {
        return 1
      }
      if (a < b) {
        return -1
      }
    },
  },
]

function sortAge(age) {
  if (age.search("minute") > 0) {
    return 1
  } else if (age.search("hour") > 0) {
    return 2
  } else if (age.search("day") > 0) {
    return 3
  }
}

const calcPercent = (num, total) => Math.round((num / total) * 100)

const PercentageDisplay = (data) => {
  const totalAmount = data.reduce((acc, val) => (acc += val.value), 0)

  return (
    <PercentageBox>
      {data.map((el) => {
        const percent = calcPercent(el.value, totalAmount)
        return (
          <p style={{ color: el.color }}>{`${percent < 1 ? "<1" : percent}% ${
            el.title
          }`}</p>
        )
      })}
    </PercentageBox>
  )
}
export default class Charts extends Component {
  state = {
    count: 0,
    loading: true,
    transactions: [],
    others: false,
    coins: [
      {
        name: "BTC(Lightning)",
        number: 0,
        value: 0,
        color: "#bd4de0",
        alias: "Lightning",
      },
      {
        name: "Binance Coin",
        number: 0,
        value: 0,
        color: "#f7ba2c",
        alias: "Binance-Coin",
      },
      {
        name: "Bitcoin",
        number: 0,
        value: 0,
        color: "#f58a0b",
        alias: "Bitcoin",
      },
      {
        name: "Litecoin",
        number: 0,
        value: 0,
        color: "#bdbdbd",
        alias: "Litecoin",
      },
      {
        name: "Others",
        number: 0,
        value: 0,
        color: "#72abfd",
        alias: "others",
      },
    ],
    pie1: [],
    pie2: [],
  }

  componentDidMount() {
    this.callWebSocket()
  }

  callWebSocket = () => {
    const self = this
    const socket = new WebSocket(
      "wss://www.livingroomofsatoshi.com/merchanttxstravelbybit"
    )
    socket.addEventListener("message", (event) => {
      self.handleData(event.data)
    })
    const socket2 = new WebSocket(
      "wss://www.livingroomofsatoshi.com/merchanttxs1775"
    )
    socket2.addEventListener("message", (event) => {
      self.handleData(event.data)
    })
  }

  handleData(data) {
    if (this.state.count < 2) {
      this.setState({
        count: this.state.count + 1,
      })
    } else {
      transactions = []
      this.setState({ count: 1 })
    }
    if (data != "ping") {
      let result = JSON.parse(data)
      this.reparse(result)
    }
  }

  convertDateToAEST(date) {
    date = date.toLocaleString("en-US", { timeZone: "Australia/Brisbane" })
    return new Date(date)
  }

  getAge(timeNow, orderTime) {
    var minutes = differenceInMinutes(new Date(timeNow), new Date(orderTime))
    var age = ""
    if (minutes < 60) {
      if (minutes > 1) {
        age = minutes + " minutes ago"
      } else {
        age = "1 minute ago"
      }
    } else if (minutes >= 60 && minutes < 1440) {
      if (minutes > 120) {
        age = Math.floor(minutes / 60) + " hours ago"
      } else {
        age = "1 hour ago"
      }
    } else if (minutes >= 1440) {
      if (minutes > 2880) {
        age = Math.floor(minutes / 1440) + " days ago"
      } else {
        age = "1 day ago"
      }
    }
    return age
  }

  reparse(obj) {
    obj.forEach((i) => {
      let a = {}
      let iDate = i.date.replace(" 20", " 2020")
      iDate = i.date.replace(" 19", " 2019")
      var orderTime = new Date(iDate)
      var timeNow = new Date()
      var timeNow = this.convertDateToAEST(new Date())
      var result = differenceInDays(timeNow, orderTime)
      let newAsset = ""
      if (i.actualAsset === "Lightning") {
        let txt = "BTC(Lightning)"
        let img = (
          <span>
            <img src={require("../../images/icon/Logo-Btc-Lightning.png")} />{" "}
            {txt}
          </span>
        )
        a.actualAsset = txt
        a.newAsset = img
      } else if (i.actualAsset === "Binance Coin") {
        let img = (
          <span>
            <img src={require("../../images/icon/Logo-Binance-Coin.png")} />
            {` `}BNB
          </span>
        )
        a.actualAsset = "Binance Coin"
        a.newAsset = img
      } else if (i.actualAsset === "Tether ERC20") {
        let img = (
          <span>
            <img src={require(`../../images/icon/Logo-Tether.png`)} />{" "}
            {i.actualAsset}
          </span>
        )
        a.actualAsset = "Others"
        a.newAsset = img
        a.details = "Tether"
      } else if (
        i.actualAsset === "Dash" ||
        i.actualAsset === "Ether" ||
        i.actualAsset === "Nem"
      ) {
        let img = (
          <span>
            <img src={require(`../../images/icon/Logo-${i.actualAsset}.png`)} />{" "}
            {i.actualAsset}
          </span>
        )
        a.actualAsset = "Others"
        a.newAsset = img
        a.details = i.actualAsset
      } else {
        let img = (
          <span>
            <img src={require(`../../images/icon/Logo-${i.actualAsset}.png`)} />{" "}
            {i.actualAsset}
          </span>
        )
        a.actualAsset = i.actualAsset
        a.newAsset = img
      }
      if (
        result < 31 &&
        i.merchant !== "Barron Valley Hotel" &&
        i.merchant !== "The Coffee Shop" &&
        i.merchant !== "Game Ranger"
      ) {
        if (result < 7) {
          a.date = i.date
          var age = this.getAge(timeNow, orderTime)
          a.age = age
          if (i.merchant) {
            if (i.merchant.search("Cater Care") != -1) {
              let n = i.merchant.search("Airport")
              let name = i.merchant.substring(n + 10)
              if (name !== "") {
                a.name = name
                a.amount = "$" + i.amount
              } else {
                a.name = "Burger Urge"
                a.amount = "$" + i.amount
              }
            } else if (i.merchant === "TravelbyBit") {
              a.name = i.merchant
              a.amount = "--"
            } else {
              a.name = i.merchant
              a.amount = "$" + i.amount
            }
          } else {
            a.name = "TravelbyBit Online Booking"
            a.amount = "--"
          }
          transactions.push(a)
        }
        let coins = this.state.coins
        let objIndex = coins.findIndex((obj) => obj.name === a.actualAsset)
        if (objIndex !== -1) {
          coins[objIndex].number = coins[objIndex].number + 1
          coins[objIndex].value = coins[objIndex].value + i.amount
        }
        this.setState({ coins: coins })
      }
    })
    transactions.sort((a, b) => {
      a = new Date(a.date)
      b = new Date(b.date)
      return a > b ? -1 : a < b ? 1 : 0
    })
    if (this.state.count === 2) {
      const newData = transactions.map((row) => row)
      this.pieChartData()
      this.props.callback(newData)
      this.setState({ loading: false, transactions: newData })
    }
  }

  pieChartData() {
    let chart1 = []
    let chart2 = []
    let coins = this.state.coins

    coins.map((c1) => {
      let chartrow = {}
      chartrow.title = c1.name
      chartrow.value = c1.number
      chartrow.color = c1.color
      chart1.push(chartrow)
    })
    coins.map((c2) => {
      let chartrow = {}
      chartrow.title = c2.name
      chartrow.value = c2.value
      chartrow.color = c2.color
      chart2.push(chartrow)
    })
    this.setState({ pie1: chart1 })
    this.setState({ pie2: chart2 })
  }

  handleOnClick = (e) => {
    if (this.state.others) {
      this.setState({ others: false })
    } else {
      this.setState({ others: true })
    }
  }

  labels = (props) => {
    const content = props.map((l) => (
      <div
        key={l.name}
        className={l.alias !== "others" ? "labelbox" : "labelbox others"}
        onClick={l.alias === "others" ? this.handleOnClick : null}
      >
        <div className="color">
          {l.alias === "others" ? (
            ""
          ) : (
            <img
              src={require(`../../images/icon/Logo-${l.alias}.png`)}
              alt={l.alias}
            />
          )}
        </div>
        <div className="labelname">
          {l.name === "Binance Coin" ? "BNB" : l.name}
          {l.alias === "others" ? (
            <FaAngleDown
              style={{ transform: "translateX(5px) translateY(2px)" }}
            />
          ) : null}
        </div>
        {l.alias === "others" && this.state.others ? (
          <div className="hover">
            <div className="column">
              <div className="color">
                <img src={require(`../../images/icon/Logo-Ether.png`)} />
              </div>
              <div className="labelname">Ethereum</div>
            </div>
            <div className="column">
              <div className="color">
                <img src={require(`../../images/icon/Logo-Dash.png`)} />
              </div>
              <div className="labelname">Dash</div>
            </div>
            <div className="column">
              <div className="color">
                <img src={require(`../../images/icon/Logo-Xem.svg`)} />
              </div>
              <div className="labelname">Nem</div>
            </div>
          </div>
        ) : null}
      </div>
    ))
    return <div className="chartlabels">{content}</div>
  }

  render() {
    const { pie1, pie2 } = this.state

    return (
      <div>
        <h1 className="h1 statstitle">TravelbyBit Stats</h1>
        <div className="piecharts">
          <div className="boxes">
            <div className="title">
              Currency by Number of Transactions (Past 30 Days)
            </div>
            <div className="pie">
              {pie1.length < 1 ? (
                <div className="lds-dual-ring"></div>
              ) : (
                <PieChart
                  data={pie1}
                  animate={true}
                  lineWidth={15}
                  labelPosition={65}
                  label={({ dataEntry, dataIndex }) => {
                    let { percentage, title } = dataEntry
                    return (
                      percentage < 1 &&
                      `${Math.round(percentage)}% ${
                        title === "Binance Coin" ? "BNB" : title
                      }`
                    )
                  }}
                  labelStyle={{
                    fontSize: "3.2px",
                    fontFamily: "sans-serif",
                  }}
                />
              )}
            </div>
          </div>
          <div className="boxes">
            <div className="title">
              Currency by Value of Transactions (Past 30 Days)
            </div>
            <div className="pie">
              {pie2.length < 1 ? (
                <div className="lds-dual-ring"></div>
              ) : (
                <PieChart
                  data={pie2}
                  animate={true}
                  lineWidth={15}
                  labelPosition={70}
                  label={({ dataEntry, dataIndex }) => {
                    let { percentage, title } = dataEntry

                    return (
                      percentage < 1 &&
                      `${Math.round(percentage)}% ${
                        title === "Binance Coin" ? "BNB" : title
                      }`
                    )
                  }}
                  labelStyle={{
                    fontSize: "3.5px",
                    fontFamily: "sans-serif",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {this.labels(this.state.coins)}
        <div className="title reactable">Live Transactions (Past 7 Days)</div>
        <Table
          loading={this.state.loading}
          data={this.state.transactions}
          columns={columns}
        />{" "}
        <p className="statclaim">
          Please note that the price of all travel bookings is automatically
          redacted to protect the privacy of travellers.
        </p>
      </div>
    )
  }
}

const PercentageBox = styled.div`
  position: relative;
  bottom: 250px;
  left: 36%;

  p {
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
`
